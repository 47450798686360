import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas menu mobile e filters
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});

//#endregion
//#region class on active nav link
document.addEventListener("DOMContentLoaded", function () {
  const links = document.querySelectorAll(".nav-item .nav-link");

  links.forEach((link) => {
    let slug = link.getAttribute("href").split("/").reverse()[0];
    if (window.location.href.indexOf(slug) > -1) {
      link.classList.add("active");
    }

    if (window.location.href === link.getAttribute("href")) {
      link.classList.add("active");
    }
  });
});

//#endregion

//#region slider
$(function () {
  $(".slider-product-base").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-product-evidenza").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".product-slider__main-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: ".product-slider__nav-slider",
    arrows: false,
  });
  $(".product-slider__nav-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".product-slider__main-slider",
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
  });
  $(".slider-lightbox").slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
});
//#endregion
//#region animated numbers
$(function () {
  $(document).on("scroll", startCounter);
  function startCounter() {
    let scrollY = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;
    let divPos = $("#counter-container").offset().top;

    if (scrollY > divPos) {
      $(document).off("scroll", startCounter);

      $(".animation-numbers__container__number").each(function () {
        var $this = $(this);
        jQuery({
          Counter: 0,
        }).animate(
          {
            Counter: $this.text().replace(/,/g, ""),
          },
          {
            duration: 2000,
            easing: "swing",
            step: function () {
              $this.text(commaSeparateNumber(Math.floor(this.Counter)));
            },
            complete: function () {
              $this.text(commaSeparateNumber(this.Counter));
            },
          }
        );
      });

      function commaSeparateNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    }
  }
});
//#endregion
//#region gallery
Fancybox.bind('[data-fancybox="gallery__lightgallery"]', {
  // Your custom options
});
//#endregion
